<template>
	<div>
		<leftNav :currentPath="'ac_organ'"></leftNav>
		<div class="js-copy js-content pd_body">
			<!-- <topHeader></topHeader> -->
			<div class="ac_organ mine_content_right">
				<div class="ac_organ_header">
					<span>我主辦的活動</span>
				</div>
				<div class="ac_organ_status">
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 4 }"
						@click="selStatusClick(4)"
					>
						全部
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 1 }"
						@click="selStatusClick(1)"
					>
						已通过
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 3 }"
						@click="selStatusClick(3)"
					>
						审核中
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 2 }"
						@click="selStatusClick(2)"
					>
						未通过
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 0 }"
						@click="selStatusClick(0)"
					>
						草稿
					</div>
				</div>

				<div class="mywz_publish_line"></div>
				<div class="ac_organ_list">
					<div class="ac_organ_list_top">
						<div class="ac_organ_list_header">
							共
							{{
								selStatusIndex == 4
									? acList.length
									: selStatusIndex == 1
									? acOkList.length
									: selStatusIndex == 2
									? acNoList.length
									: selStatusIndex == 3
									? acIngList.length
									: acTmpList.length
							}}
							场活动</div
						>
						<div
							class="ac_organ_list_header_btn mainbackgroundcolor pointer"
							@click="gotoCreate()"
						>
							创建活动</div
						>
					</div>
					<div class="ac_organ_list_content">
						<div
							v-for="(item, index) in selStatusIndex == 4
								? acList
								: selStatusIndex == 1
								? acOkList
								: selStatusIndex == 2
								? acNoList
								: selStatusIndex == 3
								? acIngList
								: acTmpList"
							:key="index"
						>
							<acItem
								:item="item"
								:type="0"
								@gotoDetail="gotoDetail"
								@gotoEdit="gotoEdit"
								@gotoDel="gotoDel"
							></acItem>
						</div>
					</div>
				</div>
			</div>
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
import { acitvityLists, acitvityDel } from "@/api/home";
import topHeader from "../../components/pc/topHeader2.vue";
import bottom from "../../components/pc/bottom.vue";
import acItem from "./ac_item_new.vue";
import { getUserId } from "@/utils/auth";
import { getToken } from "@/utils/auth";
import leftNav from "../my/leftNav.vue";

export default {
	name: "",
	components: {
		topHeader,
		bottom,
		acItem,
		leftNav,
	},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
	data() {
		return {
			selStatusIndex: 4,
			page: 1,
			size: 99,
			acList: [],
			acOkList: [],
			acIngList: [],
			acNoList: [],
			acTmpList: [],
		};
	},
	created() {
		const hasToken = getToken();
		console.log(hasToken);
		if (hasToken) {
			this.getACList({
				page: this.page,
				size: this.size,
				userid: getUserId(),
			});
		} else {
			this.$router.push({ path: "/" });
			// location.reload();
		}
	},
	methods: {
		selStatusClick(index) {
			this.selStatusIndex = index;
		},
		gotoCreate() {
			if (this.isMobileDevice) {
				this.$message({
					message: "请在PC浏览器中访问创建活动",
				});
				return;
			}
			this.$router.push({
				path: "/ac_create",
			});
		},
		gotoDetail(item) {
			this.$router.push({
				name: "/ac_detail",
				params: { id: item.id },
			});
		},
		gotoEdit(id) {
			if (this.isMobileDevice) {
				this.$message({
					message: "请在PC浏览器中访问编辑活动",
				});
				return;
			}
			this.$router.push({
				name: "/ac_create",
				params: { id: id },
			});
		},
		gotoDel(id) {
			let obj = { id: id };
			this.$loading.show();
			acitvityDel(obj)
				.then((res) => {
					this.$loading.hide();
					if (res.code == 200) {
						this.loading = false;
						this.getACList({
							page: this.page,
							size: this.size,
							userid: getUserId(),
						});
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$loading.hide();
					this.$message({ message: error.message, type: "error" });
				});
		},
		getACList(obj) {
			acitvityLists(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						if (obj.page == 1) {
							this.acList = res.data.list;
						} else {
							this.acList = this.articleList.concat(res.data.list);
						}
						for (let i = 0; i < this.acList.length; i++) {
							const item = this.acList[i];
							// 审核结果：0-草稿；1-审核通过；2-审核不通过；3-审核中
							if (item.status == 0) {
								this.acTmpList.push(item);
							} else if (item.status == 1) {
								this.acOkList.push(item);
							} else if (item.status == 2) {
								this.acNoList.push(item);
							} else if (item.status == 3) {
								this.acIngList.push(item);
							}
						}
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$message({ message: error.message, type: "error" });
				});
		},
	},
};
</script>
<style scoped>
@import url("../../../src/assets/person.css");
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	margin-top: 100px;
	width: calc(100vw);
	margin: 0 auto;
}
.ac_organ {
	/* margin-left: 10%; */
	/* width: 80%; */
}
</style>

<style scoped>
.ac_organ_header {
	margin-top: 80px;
	display: flex;
}

.ac_organ_header img {
	width: 24px;
	height: 24px;
}

.ac_organ_header span {
	font-size: 24px;
	font-weight: bold;
	line-height: 24px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.mywz_publish_line {
	width: 100%;
	height: 1px;
	opacity: 0.2;
	background: #d8d8d8;
}
</style>

<!-- ac_organ_status -->
<style scoped>
.ac_organ_status {
	margin-top: 30px;
	display: flex;
	height: 50px;
	margin-bottom: 10px;
	flex-wrap: nowrap; /* 禁止换行，确保横向排列 */
	overflow-x: auto; /* 如果内容超出屏幕宽度，允许横向滚动 */
	white-space: nowrap; /* 防止内容换行 */
}

.ac_organ_status_item_sel {
	/* 333 */
	background: rgba(239, 245, 254, 0.1);
}

.ac_organ_status_item {
	border-radius: 10px;
	opacity: 1;
	height: 33px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	align-content: center;
	text-align: center;
	align-items: center;
	letter-spacing: 0em;
	padding-left: 20px;
	padding-right: 20px;
	font-variation-settings: "opsz" auto;
	color: #fff;
	margin-right: 20px;
	flex: 0 0 auto; /* 禁止拉伸，宽度由内容决定 */
}
</style>

<!-- ac_organ_list -->
<style scoped>
.ac_organ_list {
	margin-top: 20px;
	min-height: 80vh;
}

.ac_organ_list_top {
	display: flex;
	justify-content: space-between;
}

.ac_organ_list_header {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;
	align-content: center;
	/* 三级颜色 */
	color: #7a8291;
}

.ac_organ_list_header_btn {
	width: 140px;
	height: 40px;
	border-radius: 20px;
	text-align: center;
	align-content: center;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #ffffff;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}

.ac_organ_list_content {
	margin-top: 20px;
	opacity: 1;
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
	.ac_organ {
		margin-left: 5%;
		width: 90%;
	}
	.ac_organ_header {
		margin-top: 40px;
	}

	.ac_organ_list_header_btn {
		width: 100px;
		height: 30px;
		border-radius: 15px;
		font-size: 16px;
		line-height: 18px;
	}
}
</style>
